import { BaseModule } from 'src/app/base/base.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { StoreModule } from '@ngrx/store';
import { homeReducer } from './home.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    BaseModule,
    CommonModule,
    HomeRoutingModule,
    StoreModule.forRoot({
      home: homeReducer
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 4
    })
  ]
})
export class HomeModule { }
