<div padding-20>
    <h2 i18n>Mis datos</h2>

    <form [formGroup]="frm" fxLayoutGap="20px">
        <mat-form-field fxFlex>
            <mat-label i18n>Nombre</mat-label>
            <input matInput formControlName="name" type="text" required>
            <mat-error *ngIf="hasError('name','required')" i18n>El campo es obligatorio</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex>
            <mat-label i18n>Apellidos</mat-label>
            <input matInput formControlName="lastName" type="text" required>
            <mat-error *ngIf="hasError('lastName','required')" i18n>El campo es obligatorio</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex>
            <mat-label i18n>Email</mat-label>
            <input matInput formControlName="email" type="text" required>
            <mat-error *ngIf="hasError('email','email')" i18n>Debe ser un mail válido</mat-error>
            <mat-error *ngIf="hasError('email','required')" i18n>El campo es obligatorio</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex>
            <mat-label i18n>Edad</mat-label>
            <input matInput formControlName="age" type="number" required>
            <mat-error *ngIf="hasError('age','required')" i18n>El campo es obligatorio</mat-error>
        </mat-form-field>
    </form>

    <div appMe>
        <pre>{{
        frm.value | json 
    }}</pre>
    </div>

    <div appMe>
        Directiva

        <div appMe>{{frm.value | me}}</div>
    </div>

    <div>
        Pipe
        <div>{{frm.value | me}}</div>
    </div>

</div>