interface PageInterface {
  title: string;
  description?: string;
  keywords?: string;
}


export class Page implements PageInterface {
  title: string;
  description?: string;
  keywords?: string;

  constructor(title: string, description?: string, keywords?: string) {
    this.title = title || '';
    this.description = description || '';
    this.keywords = keywords || '';
  }

  setDescription(description: string) {
    this.description = description;
    return this;
  }

  addKeyword(keyword: string) {
    if (keyword) {
      let keywords = this.keywords.split(',');
      keywords = keywords.filter(key => key.length > 0);
      keywords.push(keyword.trim());
      this.keywords = keywords.join(',');
    }
    return this;
  }

}
