import { Service } from './Service';
import { Injectable } from '@angular/core';
import { Cache } from './Cache';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService extends Service {

  options: any;
  url: string;
  baseUrl: string;

  constructor(public http: HttpClient) {
    super();
    this.options = {
      withCredentials: true
    };
    this.url = this.baseUrl ? this.baseUrl : this.url;
  }

  authOptions(options: any): any {
    return Object.assign(options || {}, this.options);
  }

  list(options?: any): Observable<any> {
    return this.http.get(this.url, this.authOptions(options));
  }

  create(item: any, options?: any): Observable<any> {
    return this.http.post(this.url, item, this.authOptions(options));
  }

  read(id: string, options?: any): Observable<any> {
    return this.http.get(this.urlWithId(id), this.authOptions(options));
  }

  delete(id: string, options?: any): Observable<any> {
    return this.http.delete(this.urlWithId(id), this.authOptions(options));
  }

  deleteWithParams(id: string, item: any, options?: any): Observable<any> {
    const deleteOptions = this.authOptions(options);
    deleteOptions.body = item;
    return this.http.delete(this.urlWithId(id), deleteOptions);
  }

  update(id: string, item: any, options?: any): Observable<any> {
    return this.http.put(this.urlWithId(id), item, this.authOptions(options));
  }

  partial(id: string, item: any, options?: any): Observable<any> {
    return this.http.patch(this.urlWithId(id), item, this.authOptions(options));
  }

  setPath(...args: string[]): AuthApiService {
    this.url = [this.baseUrl, ...args].join('/');
    return this;
  }

  private urlWithId(id: string): string {
    return [this.url, id].join('/');
  }
}
