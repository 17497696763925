<!-- beautify ignore:start -->
<app-page-title
  i18n-title
  title="Aviso Legal y Responsabilidad"
  i18n-description
  description="Política de privacidad, Responsabilidad, Tratamiento de Datos,  Nota Legal y Recomendaciones"
  i18n-keywords
  keywords="[Aviso legal y Responsabilidad, Política de privacidad,Responsabilidad,Tratamiento de Datos, Nota Legal,Recomendaciones]"
>
</app-page-title>
<mat-card>

  <div fxLayout="row">
    <div fxHide.xs fxFlex="200px" class="privacy-menu">
      <mat-nav-list role="list" dense class="nav-list-fixed">
        <a (keyup)="go(a01)" (click)="go(a01)" role="button" tabindex="0" mat-list-item i18n>Política de privacidad</a>
        <a (keyup)="go(a02)" (click)="go(a02)" role="button" tabindex="0" mat-list-item i18n>Responsable del tratamiento</a>
        <a (keyup)="go(a03)" (click)="go(a03)" role="button" tabindex="0" mat-list-item i18n>Finalidad</a>
        <a (keyup)="go(a04)" (click)="go(a04)" role="button" tabindex="0" mat-list-item i18n>Legitimación</a>
        <a (keyup)="go(a05)" (click)="go(a05)" role="button" tabindex="0" mat-list-item i18n>Conservación de datos</a>
        <a (keyup)="go(a06)" (click)="go(a06)" role="button" tabindex="0" mat-list-item i18n>Comunicación de datos</a>
        <a (keyup)="go(a07)" (click)="go(a07)" role="button" tabindex="0" mat-list-item i18n>Derechos de los interesados</a>
        <a (keyup)="go(a08)" (click)="go(a08)" role="button" tabindex="0" mat-list-item i18n>Cookies</a>
        <a (keyup)="go(a09)" (click)="go(a09)" role="button" tabindex="0" mat-list-item i18n>Jurisdicción y Legislación aplicable</a>
        <a (keyup)="go(a10)" (click)="go(a10)" role="button" tabindex="0" mat-list-item i18n>Recomendación</a>
      </mat-nav-list>
    </div>
    <div fxFlex class="privacy">
      <h1 class="mat-h1" i18n>Aviso Legal y Responsabilidad</h1>
      <h2 #a01 class="mat-h2" i18n>Política de privacidad</h2>
      <div class="mat-body-1">
        <p i18n>La Administración de Memorart respeta la legislación vigente en materia de protección de datos personales, la privacidad de los interesados y el secreto y seguridad de los datos personales, de conformidad con lo establecido en la legislación aplicable en materia de protección de datos, en concreto, el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016, adoptando para ello las medidas técnicas y organizativas necesarias para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos personales facilitados, teniendo en cuenta el estado de la tecnología, la naturaleza de los datos y los riesgos a los que están expuestos.</p>
        <p i18n>Se informa a los usuarios de este portal, que sus datos de carácter personal sólo podrán obtenerse para tu tratamiento cuando sean adecuados, pertinentes y no excesivos en relación con el ámbito y las finalidades determinadas, explícitas y legítimas para las que se hayan obtenido.</p>
        <p i18n>Cuando se recaben datos personales a través de cualquier portal de la Administración de Memorart, se informará previamente al usuario, de forma clara e inequívoca, de los siguientes extremos:</p>
        <ul>
          <li i18n>Existencia de un tratamiento de datos de carácter personal.</li>
          <li i18n>La identidad y los datos de contacto del responsable del tratamiento.</li>
          <li i18n>Los datos de contacto del Delegado de Protección de Datos.</li>
          <li i18n>Los fines del tratamiento a que se destinan los datos personales y la base jurídica del tratamiento.</li>
          <li i18n>Los destinatarios o las categorías de destinatarios de los datos personales, en su caso.</li>
          <li i18n>En su caso, la intención del responsable del tratamiento de transferir datos personales a un tercer país.</li>
          <li i18n>El plazo o los criterios de conservación de la información.</li>
          <li i18n>La existencia del derecho a solicitar al responsable del tratamiento el acceso a los datos personales relativos al interesado, y su rectificación o supresión, o la limitación de su tratamiento, el derecho a la portabilidad de los datos, o a oponerse al tratamiento. Asimismo, la manera de ejercitar los derechos indicados.</li>
          <li i18n>Cuando el tratamiento esté basado en el consentimiento del interesado. La existencia del derecho a retirar el consentimiento en cualquier momento, sin que ello afecta a la licitud del tratamiento basado en el consentimiento previo a su retirada. Si el tratamiento no está basado en el consentimiento, su derecho a ejercer la oposición al tratamiento.</li>
          <li i18n>El derecho a presentar una reclamación ante una autoridad de control.</li>
          <li i18n>La existencia, si las hubiera, de decisiones automatizadas, incluida la elaboración de perfiles y el ejercicio de derechos asociados a dicho tratamiento.</li>
        </ul>
        <p i18n>El usuario será el único responsable a causa de la cumplimentación de los formularios con datos falsos, inexactos, incompletos o no actualizados.</p>
        <h2 #a02 class="mat-h2" i18n>Responsable del tratamiento</h2>
        <p i18n>Los datos de carácter personal que se pudieran recabar directamente del interesado serán tratados de forma confidencial y quedarán incorporados a la correspondiente actividad de tratamiento titularidad de la Administración de Memorart.</p>
        <p i18n>La relación actualizada de las actividades de tratamiento que la Administración de Memorart lleva a cabo, se encuentra disponible en la sede electrónica a través del siguiente enlace https://memorart.com</p>
        <h2 #a03 class="mat-h2" i18n>Finalidad</h2>
        <p i18n>La finalidad del tratamiento de los datos corresponde a cada una de las actividades de tratamiento que realiza la Administración de Memorart y que están accesibles en la sede electrónica a través del siguiente enlace https://memorart.com.</p>
        <h2 #a04 class="mat-h2" i18n>Legitimación</h2>
        <p i18n>El tratamiento de sus datos se realiza para el cumplimiento de obligaciones legales por parte de la Administración de Memorart, para el cumplimiento de misiones realizada en interés público o en el ejercicio de poderes públicos conferidos a la Administración de Memorart, así como cuando la finalidad del tratamiento requiera su consentimiento, que habrá de ser prestado mediante una clara acción afirmativa.</p>
        <p i18n>Puede consultar la base legal para cada una de las actividades de tratamiento que lleva a cabo la Administración de Memorart en el siguiente enlace https://memorart.com.</p>
        <h2 #a05 class="mat-h2" i18n>Conservación de datos</h2>
        <p i18n>Los datos personales proporcionados se conservarán durante el tiempo necesario para cumplir con la finalidad para la que se recaban y para determinar las posibles responsabilidades que se pudieran derivar de la finalidad, además de los períodos establecidos en la normativa de archivos y documentación.</p>
        <h2 #a06 class="mat-h2" i18n>Comunicación de datos</h2>
        <p i18n>Con carácter general no se comunicarán los datos personales a terceros, salvo obligación legal, entre las que pueden estar las comunicaciones al Defensor del Pueblo, Jueces y Tribunales, interesados en los procedimientos relacionados con las reclamaciones presentadas.</p>
        <p i18n>Puede consultar los destinatarios para cada una de las actividades de tratamiento que lleva a cabo la Administración de Memorart en el siguiente enlace https://memorart.com.</p>
        <h2 #a07 class="mat-h2" i18n>Derechos de los interesados</h2>
        <p i18n>Cualquier persona tiene derecho a obtener confirmación sobre los tratamientos que de sus datos que se llevan a cabo por la Administración de Memorart.</p>
        <p i18n>Puede ejercer sus derechos de acceso, rectificación, supresión y portabilidad de sus datos, de limitación y oposición a su tratamiento, así como a no ser objeto de decisiones basadas únicamente en el tratamiento automatizado de sus datos, cuando procedan, a través del Servicio de Atención Ciudadana (SAC) de la Administración de Memorart, presentando el formulario normalizado, disponible en https://memorart.comes, que podrá localizar introduciendo el código 201200088 en el buscador de la cabecera (situado en la parte superior derecha de la página), electrónicamente o presencialmente a través del Registro General Central de la Administración de Memorart o por cualquiera de los medios establecidos en la Ley de Procedimiento Administrativo.</p>
        <p i18n>Sin perjuicio de cualquier otro recurso administrativo o acción judicial, todo interesado tendrá derecho a presentar una reclamación ante una Autoridad de Control en materia de Protección de Datos competente, si considera que el tratamiento de datos personales que le concierne, infringe la normativa en materia de Protección de datos personales.</p>
        <h2 #a08 class="mat-h2" i18n>Cookies</h2>
        <p i18n>Con su navegación, usted presta el consentimiento para recibir las cookies que utiliza este sitio web, que en ningún caso se emplean para recoger información de carácter personal. Son de dos tipos:</p>
        <ul>
          <li i18n>Cookies propias: son enviadas a su equipo y gestionadas exclusivamente por la Administración de Memorart, que sólo obtiene y conserva la información relativa a los datos de sesión e idioma. Estas cookies son necesarias para el correcto funcionamiento y visualización del sitio web por parte del usuario.</li>
          <li i18n>Cookies analíticas de tercero: el sitio web usa las cookies de la herramienta Google Analytics, que realiza una medición y análisis estadístico de la navegación de los usuarios en la web, con el fin de mejorar los servicios que ofrece el mismo. La información que genera la cookie es directamente transmitida y archivada por Google.</li>
        </ul>
        <p i18n>Para garantizar el anonimato, Google convierte su información en anónima truncando la dirección IP antes de almacenarla. Tampoco asocia su dirección IP truncada a ninguno otro dato conservado por Google, ni puede enviar la información recogida por Google Analytics a terceros salvo cuando esté legalmente obligado a hacerlo, conforme las condiciones de prestación del servicio de Google Analytics.</p>
        <p><span i18n>Si usted desea denegar el permiso para el tratamiento estadístico de los datos o de la información con Google Analytics, clique </span>
          <a href="https://tools.google.com/dlpage/gaoptout?hl=es" target="_blank" i18n>aquí.</a>
        </p>
        <p i18n>Le recordamos que deberá ejecutar esta acción cada vez que cambie de navegador o su configuración, o si elimina las cookies, puesto que el indicador de denegación se guarda en una cookie.</p>
        <h2 #a09 class="mat-h2" i18n>Jurisdicción y Legislación aplicable</h2>
        <p i18n>Para cuantas controversias pudieran surgir en la interpretación, aplicación o cumplimiento de las presentes Licencias y Términos Legales, así como los derivados de la utilización de los contenidos y los datos, serán competentes los Juzgados y Tribunales del domicilio de la Administración de Memorart quienes aplicarán la legislación del lugar.</p>
        <h2 #a10 class="mat-h2" i18n>Recomendación</h2>
        <p i18n>Se insta a todas las personas o entidades que utilicen, reproduzcan, modifiquen o distribuyan los contenidos y los conjuntos de datos, a que pongan en conocimiento de la Administración de Memorart todos los proyectos relacionados o derivados del uso de los contenidos y los conjuntos de datos, de modo que sean expuestos de forma pública con el fin de estimular las políticas de reutilización de información del sector público y aprovechamiento común por todos los usuarios y agentes.</p>
      </div>
    </div>
  </div>
</mat-card>

<!-- beautify ignore:end -->