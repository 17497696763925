import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { colors } from './colors';

export interface Options {
  arrowSize: number,
  colors: string[],
  highlight: string;
  icons: string;
  imageMap: {},
  images: string;
  infoPanel: boolean;
  minCollision: string;
  neo4jData: string;
  neo4jDataUrl: string;
  nodeOutlineFillColor: string;
  nodeRadius: number,
  relationshipColor: string,
  zoomFit: boolean;
};

@Component({
  selector: 'app-neo4j-d3',
  templateUrl: './neo4j-d3.component.html',
  styleUrls: ['./neo4j-d3.component.scss']
})
export class Neo4jD3Component implements OnInit {

  constructor() { }
  options: Options = {
    arrowSize: 4,
    colors: colors,
    highlight: undefined,
    icons: undefined,
    imageMap: {},
    images: undefined,
    infoPanel: true,
    minCollision: undefined,
    neo4jData: undefined,
    neo4jDataUrl: undefined,
    nodeOutlineFillColor: undefined,
    nodeRadius: 25,
    relationshipColor: '#a5abb6',
    zoomFit: false
  };
  readonly version = '1.0.0';

  ngOnInit(): void {
    console.log('componente iniciado!')
    this.init();
  }


  private init() {

  }

}
