import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuService } from 'src/app/base/components/menu/menu.service';
import { ConditionsComponent } from './conditions.component';
const path = 'service-conditions';
const routes: Routes = [{
  path,
  component: ConditionsComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConditionsRoutingModule {
  constructor(private menuService: MenuService) {
    this.menuService.addPublicItem('Condiciones del Servicio', path);
  }
}
