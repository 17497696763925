import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './layout/layout.module';
import { MaterialsModule } from './materials/materials.module';
import { FormModule } from './form/form.module';
import { HttpClientModule } from '@angular/common/http';
import { ComponentsModule } from './components/components.module';
import { MomentModule } from './moment/moment.module';
import { ApiModule } from './api/api.module';

const BASE_MODULES = [
  ApiModule,
  ComponentsModule,
  FormModule,
  HttpClientModule,
  LayoutModule,
  MaterialsModule,
  MomentModule
];

@NgModule({
  declarations: [],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    ...BASE_MODULES
  ],
  exports: [
    ...BASE_MODULES
  ]
})
export class BaseModule { }
