import { BaseModule } from 'src/app/base/base.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CookiesPolicyRoutingModule } from './cookies-policy-routing.module';
import { CookiesPolicyComponent } from './cookies-policy.component';

@NgModule({
  declarations: [
    CookiesPolicyComponent
  ],
  imports: [
    BaseModule,
    CommonModule,
    CookiesPolicyRoutingModule,
  ]
})
export class CookiesPolicyModule {

}
