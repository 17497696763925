import { Directive, ElementRef, OnInit, HostListener } from '@angular/core';

@Directive({
  selector: '[appMe]'
})
export class MeDirective {
  classList: any;
  constructor(public el: ElementRef) {
    // el.nativeElement.style.backgroundColor = 'yellow';
    this.classList = el.nativeElement.classList;
    this.classList.add('account');
  }

  @HostListener('mouseenter') onMouseEnter(): void {
    this.classList.add('over');
    const button = document.createElement('button');
    const buttonClassList = button.classList;
    buttonClassList.add('me-directive');
    this.el.nativeElement.append(button);
    button.innerText = 'editar';
    this.el.nativeElement.onclick = this.action;
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    this.classList.remove('over');
    // document.querySelectorAll('button.me-directive').forEach(btn => btn.remove());
    document.querySelector('button.me-directive').remove();
  }

  action(event: any): void {
    console.log('has realizado una acción', event.target.innerText);
  }


}
