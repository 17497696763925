import { OauthComponent } from './oauth.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const path = 'index.html';
const routes: Routes = [{
  path,
  component: OauthComponent
}, {
  path: 'auth',
  component: OauthComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OauthRoutingModule { }
