import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuService } from 'src/app/base/components/menu/menu.service';
import { CookiesPolicyComponent } from './cookies-policy.component';

const path = 'cookies-policy';

const routes: Routes = [{
  path,
  component: CookiesPolicyComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CookiesPolicyRoutingModule {
  constructor(private menuService: MenuService) {
    this.menuService.addPublicItem('Política de Cookies', path);
  }
}
