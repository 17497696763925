import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConditionsRoutingModule } from './conditions-routing.module';
import { ConditionsComponent } from './conditions.component';
import { BaseModule } from 'src/app/base/base.module';


@NgModule({
  declarations: [ConditionsComponent],
  imports: [
    CommonModule,
    BaseModule,
    ConditionsRoutingModule,
  ]
})
export class ConditionsModule { }
