import { MaterialsModule } from './../../materials/materials.module';
import { authGoogleConfig } from './oauth.google.config';
import { LayoutModule } from '../../layout/layout.module';
import { OauthRoutingModule } from './oauth-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OauthComponent } from './oauth.component';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { authConfig } from './oauth.config';
import { OauthInterceptor } from './oauth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { MaterialsModule } from 'src/app/base/materials/materials.module';

const OAUTH_COMPONENTS = [
  OauthComponent
];

@NgModule({
  declarations: [
    ...OAUTH_COMPONENTS
  ],
  imports: [
    CommonModule,
    MaterialsModule,
    LayoutModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['http://www.angular.at/api'],
        sendAccessToken: true
      }
    }),
    OauthRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OauthInterceptor,
      multi: true
    }],
  exports: [
    ...OAUTH_COMPONENTS
  ]
})
export class OauthModule {

  constructor(private oauthService: OAuthService) {
    this.configure();
  }

  private configure() {
    this.oauthService.configure(authGoogleConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }
}
