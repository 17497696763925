export interface MenuItemInterface {
  label: string;
  route: string;
}

export interface MenuItemsInterface {
  private: MenuItemInterface[];
  public: MenuItemInterface[];
  dev: MenuItemInterface[];
  addPrivate(item: MenuItemInterface): void;
  addPublic(item: MenuItemInterface): void;
  addDev(item: MenuItemInterface): void;
}

export class MenuItem implements MenuItemInterface {
  label: string;
  route: string;

  constructor(label: string, path: string) {
    this.label = label;
    this.route = path;
  }
}

export class MenuItems implements MenuItemsInterface {

  private: MenuItem[];
  public: MenuItem[];
  dev: MenuItem[];

  static RemoveDuplicates(items, prop) {
    const result = [];
    const lookupObject = {};

    // tslint:disable-next-line:forin
    for (const i in items) {
      lookupObject[items[i][prop]] = items[i];
    }

    // tslint:disable-next-line:forin
    for (const i in lookupObject) {
      result.push(lookupObject[i]);
    }
    return result;
  }

  constructor() {
    this.private = [];
    this.public = [];
    this.dev = [];
  }

  addPrivate(item: MenuItem): void {
    this.addItem(item, 'private');
  }
  addPublic(item: MenuItem): void {
    this.addItem(item, 'public');
  }
  addDev(item: MenuItem): void {
    this.addItem(item, 'dev');
  }

  private addItem(item: MenuItem, array: string) {
    this[array].push(item);
    this[array] = MenuItems.RemoveDuplicates(this[array], 'route');
  }

}
