import { MaterialsModule } from 'src/app/base/materials/materials.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogPolicyComponent } from './dialog-policy.component';
import { LayoutModule } from 'src/app/base/layout/layout.module';

const DIALOG_POLICY_COMPONENTS = [
  DialogPolicyComponent
];

@NgModule({
  imports: [
    CommonModule,
    MaterialsModule,
    LayoutModule
  ],
  declarations: [
    ...DIALOG_POLICY_COMPONENTS
  ],
  exports: [
    ...DIALOG_POLICY_COMPONENTS
  ]
})
export class DialogPolicyModule { }
