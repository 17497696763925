import { Neo4jD3Module } from './neo4j-d3/neo4j-d3.module';
import { MenuModule } from './menu/menu.module';
import { CookiesModule } from './cookies/cookies.module';
import { PageTitleModule } from './page-title/page-title.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderModule } from './header/header.module';
import { FooterModule } from './footer/footer.module';

const COMPONENT_MODULES = [
  CookiesModule,
  FooterModule,
  HeaderModule,
  MenuModule,
  Neo4jD3Module,
  PageTitleModule
];
@NgModule({
  declarations: [],
  imports: [
    ...COMPONENT_MODULES,
    CommonModule
  ], exports: [
    ...COMPONENT_MODULES,
  ]
})
export class ComponentsModule { }
