import { Cache } from './Cache';

interface FilterOption { $filter: string; }
interface PageOption { $page: string; }
interface SortOption { $sort: string; }
interface FieldsOption { $fields: string; }
interface SearchOption { $search: string; }

export class Service extends Cache {

  static search(search: string): SearchOption {
    return { $search: search };
  }
  static fields(...fields: string[]): FieldsOption {
    return { $fields: fields.join(';') };
  }
  static page(page: any): PageOption {
    return { $page: Service.encode({ index: page.pageIndex, size: page.pageSize, length: page.length }) };
  }
  static filter(filter: any): FilterOption {
    return { $filter: Service.encode(filter) };
  }
  static sort(sort: any): SortOption {
    return { $sort: Service.encode({ [sort.active]: sort.start }) };
  }
  static encode(obj: any): string {
    return typeof obj === 'object' ? JSON.stringify(obj).split('"').join('')
      .split('{').join('').split('}').join('').split(',').join(';') : null;
  }

  constructor() {
    super();
  }
}
