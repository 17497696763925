import { Injectable, EventEmitter, Output } from '@angular/core';
import { MenuItems, MenuItem } from './MenuItem';
import { of, Observable } from 'rxjs';

interface MenuServiceInterface {
  items: MenuItems;

  addPrivateItem(label: string, path: string);
  addPublicItem(label: string, path: string);
  addDevelopItem(label: string, path: string);
}
@Injectable({
  providedIn: 'root'
})
export class MenuService implements MenuServiceInterface {

  @Output() data: EventEmitter<MenuItems> = new EventEmitter();

  items = new MenuItems();

  addPrivateItem(label: string, path: string) {
    this.items.addPrivate(new MenuItem(label, path));
  }
  addPublicItem(label: string, path: string) {
    this.items.addPublic(new MenuItem(label, path));
  }
  addDevelopItem(label: string, path: string) {
    this.items.addDev(new MenuItem(label, path));
  }

  list(): Observable<MenuItems> {
    return of(this.items);
  }
}


