import { OauthModule } from './../oauth/oauth.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { MaterialsModule } from '../../materials/materials.module';
import { LayoutModule } from '../../layout/layout.module';
const HEADER_COMPONENTS = [
  HeaderComponent
];

@NgModule({
  declarations: [
    ...HEADER_COMPONENTS
  ],
  imports: [
    MaterialsModule,
    LayoutModule,
    CommonModule,
    OauthModule

  ], exports: [
    ...HEADER_COMPONENTS
  ]
})
export class HeaderModule { }
