export class Technology {
    name: string;
    state: 'hold' | 'assess' | 'trial' | 'adopt';
    description: string;
    constructor(name: string, state: 'hold' | 'assess' | 'trial' | 'adopt', description: string) {
        this.name = name;
        this.state = state;
        this.description = description;
    }
}

export class Sector {
    name: string;
    description: string;
    technologies: Technology[] = [];

    constructor(name: string, description: string) {
        this.name = name;
        this.description = description;
    }

    add(technology: Technology): void {
        const index = this.technologies.findIndex(tech => tech.name === technology.name);
        if (index < 0) {
            this.technologies.push(technology);
        }
    }

    remove(technology: Technology): void {
        const index = this.technologies.findIndex(tech => tech.name === technology.name);
        if (index >= 0) {
            this.technologies.splice(index, 1);
        }
    }
}

export class TechRadar {
    techniques: Sector = new Sector('Techniques', 'Técnicas');
    tools: Sector = new Sector('Tools', 'Herramientas');
    platforms: Sector = new Sector('Platforms', 'Plataformas');
    languages: Sector = new Sector('Languages & Frameworks', 'Lenguajes y Frameworks');
    states: string[] = ['adopt', 'trial', 'assess', 'hold'];
    constructor() {
    }
}


export class Tech extends Technology {
    x: number;
    y: number;
    color: string;
    index: number;
}
