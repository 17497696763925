import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { moment } from 'src/app/base/moment/moment';


@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private meta: Meta, private title: Title) {
    this.meta.addTag({ name: 'date', content: moment().format('DD-MM-YYYY'), scheme: 'DD-MM-YYYY' });
  }

  updateTitle(title: string) {
    if (title) {
      this.title.setTitle(title);
    }
  }

  updateDescription(description: string) {
    if (description) {
      this.meta.updateTag({ name: 'description', content: description });
    }
  }

  updateKeywords(keywords: string) {
    if (keywords) {
      this.meta.updateTag({ name: 'keywords', content: keywords });
    }
  }

  // addMetaTags() { }

  // getMetaTags() {
  //   const metaEl: HTMLMetaElement = this.meta.getTag('name= "keywords"');
  //   console.log(metaEl);
  //   console.log(metaEl.name);
  //   console.log(metaEl.content);

  //   const els: HTMLMetaElement[] = this.meta.getTags('name');
  //   els.forEach(el => {
  //     console.log(el);
  //     console.log(el.name);
  //     console.log(el.content);
  //   });
  // }
  // updateMetaTags() {
  //   this.meta.updateTag({ name: 'description', content: 'Updated: Title and Meta tags examples' });
  //   this.meta.updateTag({ httpEquiv: 'Content-Type', content: 'application/json' }, 'httpEquiv= "Content-Type"');
  //   this.meta.updateTag({ name: 'robots', content: 'NOINDEX, NOFOLLOW' });
  //   this.meta.updateTag({ name: 'keywords', content: 'JavaScript, Angular' });
  //   this.meta.updateTag({ name: 'date', content: '2018-06-03', scheme: 'YYYY-MM-DD' });
  //   this.meta.updateTag({ name: 'author', content: 'VXYZ' });
  //   this.meta.updateTag({ charset: 'UTF-16' }, 'charset= "UTF-8"');
  //   this.meta.updateTag({ property: 'og:title', content: 'My Text2' });
  //   this.meta.updateTag({ property: 'og:type', content: 'website' });
  // }
  // removeMetaTags() {
  //   // Using removeTag
  //   this.meta.removeTag('name = "description"');
  //   this.meta.removeTag('name= "keywords"');
  //   this.meta.removeTag('name = "viewport"');
  //   this.meta.removeTag('name = "robots"');

  //   // Using removeTagElement
  //   const author: HTMLMetaElement = this.meta.getTag('name = "author"');
  //   this.meta.removeTagElement(author);
  //   const date: HTMLMetaElement = this.meta.getTag('name = "date"');
  //   this.meta.removeTagElement(date);
  //   const contentType: HTMLMetaElement = this.meta.getTag('httpEquiv = "Content-Type"');
  //   this.meta.removeTagElement(contentType);
  //   const charset: HTMLMetaElement = this.meta.getTag('charset');
  //   this.meta.removeTagElement(charset);
  //   const ogTitle: HTMLMetaElement = this.meta.getTag('property = "og:title"');
  //   this.meta.removeTagElement(ogTitle);
  //   const ogType: HTMLMetaElement = this.meta.getTag('property = "og:type"');
  //   this.meta.removeTagElement(ogType);
  // }
}
