import { HomeState } from './home.reducer';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromMessage from './message.action';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  data$: Observable<string>;

  constructor(private store: Store<any>) {
    this.data$ = this.store.select('home');
  }

  ngOnInit(): void {
  }

  spanishMessage() {
    this.store.dispatch(new fromMessage.SpanishMessage('los colores'));
  }

  englishMessage() {
    this.store.dispatch(new fromMessage.EnglishMessage('the colors'));
  }

}
