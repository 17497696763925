import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-me',
  templateUrl: './me.component.html',
  styleUrls: ['./me.component.scss']
})
export class MeComponent implements OnInit {

  frm: FormGroup;
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.createForm();
  }


  hasError(field: string, validation: string): boolean {
    return this.frm.controls[field].hasError(validation);
  }

  private createForm(): void {
    this.frm = this.fb.group({
      name: ['Carmelo', [Validators.required]],
      lastName: ['Huesca Calatayud', [Validators.required]],
      email: ['carmelo.huesca@ricoh.es', [Validators.required, Validators.email]],
      age: [42, [Validators.min(18), Validators.max(200)]],
    });
  }

}
