<div class="svg-wrapper">
    <svg style="border:1px solid red" *ngIf="size" [attr.height]="size" [attr.width]="size" xmlns="http://www.w3.org/2000/svg">
        <ellipse [attr.fill]="holdColor" stroke-width="1.5" [attr.cx]="0" [attr.cy]="size" id="hold_circle"
            [attr.rx]="size" [attr.ry]="size" stroke-dasharray="10,5" [attr.stroke]="lightgrey"></ellipse>
        <ellipse [attr.fill]="assessColor" stroke-width="1.5" [attr.cx]="0" [attr.cy]="size" id="assess_circle"
            [attr.rx]="size -(size/4)" [attr.ry]="size -(size/4)" stroke-dasharray="10,5" [attr.stroke]="lightgrey">
        </ellipse>
        <ellipse [attr.fill]="trialColor" stroke-width="1.5" [attr.cx]="0" [attr.cy]="size" id="trial_circle"
            [attr.rx]="size - (size/4)*2" [attr.ry]="size -(size/4)*2" stroke-dasharray="10,5"
            [attr.stroke]="lightgrey">
        </ellipse>
        <ellipse [attr.fill]="adoptColor" stroke-width="1.5" [attr.cx]="0" [attr.cy]="size" id="adopt_circle"
            [attr.rx]="size - (size/4)*3" [attr.ry]="size -(size/4)*3" stroke-dasharray="10,5"
            [attr.stroke]="lightgrey"></ellipse>
        <text [attr.fill]="lightgrey" [attr.x]="size/100" [attr.y]="size - size/100" id="svg_7"
            [attr.font-size]="size/30" font-family="Helvetica, Arial, sans-serif" text-anchor="start"
            xml:space="preserve" font-weight="bold">Adoptar</text>
        <text [attr.fill]="lightgrey" [attr.x]="size -(size/4)*3 + size/100" [attr.y]="size - size/100" id="svg_7"
            [attr.font-size]="size/30" font-family="Helvetica, Arial, sans-serif" text-anchor="start"
            xml:space="preserve" font-weight="bold">Probar</text>
        <text [attr.fill]="lightgrey" [attr.x]="size -(size/4)*2 + size/100" [attr.y]="size - size/100" id="svg_7"
            [attr.font-size]="size/30" font-family="Helvetica, Arial, sans-serif" text-anchor="start"
            xml:space="preserve" font-weight="bold">Evaluar</text>
        <text [attr.fill]="lightgrey" [attr.x]="size -(size/4) + size/100" [attr.y]="size - size/100" id="svg_7"
            [attr.font-size]="size/30" font-family="Helvetica, Arial, sans-serif" text-anchor="start"
            xml:space="preserve" font-weight="bold">Mantener</text>

        <text [attr.fill]="lightgrey" [attr.x]="size - 20" y="20" id="svg_7" [attr.font-size]="28"
            font-family="Helvetica, Arial, sans-serif" text-anchor="end" xml:space="preserve" stroke="rgba(0,0,0,.8)"
            font-weight="bold">Herramientas</text>

        <ellipse class="tech" (mouseover)="visible = true" (mouseout)="visible = false" [attr.fill]="tech.color"
            *ngFor="let tech of technologies" stroke-width="1.5" [attr.cx]="tech.x" [attr.cy]="tech.y" id="tech"
            (click)="display(tech)" [attr.rx]="size/100" [attr.ry]="size/100" stroke="rgba(0,0,0,.8)"></ellipse>

    </svg>
</div>
<div>

    <div fxFlex>
        <canvas id="canvas" [attr.width]="size" [attr.height]="size"></canvas>
    </div>
    <div fxLayout="column">
        <button mat-button (click)="displayTechniques()">Técnicas</button>
        <button mat-button (click)="displayTools()">Herramientas</button>
        <button mat-button (click)="displayPlatforms()">Plataformas</button>
        <button mat-button (click)="displayLanguages()">Lenguajes y Frameworks</button>
        <!-- <button mat-button (click)="displayLanguages()">Lenguajes y Frameworks</button> -->
    </div>
</div>