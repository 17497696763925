import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MeRoutingModule } from './me-routing.module';
import { MeComponent } from './me.component';
import { BaseModule } from 'src/app/base/base.module';
import { MeDirective } from './me.directive';
import { MePipe } from './me.pipe';


@NgModule({
  declarations: [
    MeComponent,
    MeDirective,
    MePipe
  ],
  imports: [
    BaseModule,
    CommonModule,
    MeRoutingModule
  ]
})
export class MeModule { }
