import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MenuItem, MenuItems } from './MenuItem';
import { MenuService } from './menu.service';
// import { LoginService } from 'src/app/routes/login/login.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  @Input() drawerL;

  logged: boolean;
  privateItems: MenuItem[] = [];
  publicItems: MenuItem[] = [];
  devItems: MenuItem[] = [];

  loggedSubscription: Subscription;
  isLoggedSubscription: Subscription;

  menuitems: MenuItems = new MenuItems();

  constructor(
    // private loginService: LoginService,
    private menuService: MenuService,
    private router: Router
  ) {
    this.filter();
    this.sortMenu();
  }

  ngOnInit() {
    // this.production = environment.production;
    // this.isLoggedSubscription = this.loginService.isLogged().subscribe(logged => this.logged = logged);
    // this.loggedSubscription = this.loginService.logged.subscribe(logged => this.logged = logged);
    this.menuService.list().subscribe(items => this.populate(items));
  }

  ngOnDestroy(): void {
    this.loggedSubscription.unsubscribe();
    this.isLoggedSubscription.unsubscribe();
  }

  navigateTo(path: string) {
    this.router.navigate([path]);
    this.drawerL.close();
  }

  keyNavigateTo(ev: KeyboardEvent, path: string) {
    if (ev.keyCode === 13) {
      this.router.navigate([path]);
      this.drawerL.close();
    }
  }

  filter(ev?: any) {
    if (ev && ev.target.value && ev.target.value.length > 0) {
      this.privateItems = this.menuitems.private.filter(item => this.applyFilter(item, ev.target.value));
      this.publicItems = this.menuitems.public.filter(item => this.applyFilter(item, ev.target.value));
      this.devItems = this.menuitems.dev.filter(item => this.applyFilter(item, ev.target.value));
    } else {
      this.privateItems = this.menuitems.private;
      this.publicItems = this.menuitems.public;
      this.devItems = this.menuitems.dev;
    }
  }

  private applyFilter(item: MenuItem, value: string) {
    const compare = item.label.toLowerCase().includes(value.toLowerCase());
    return compare;
  }

  private populate(items: MenuItems) {
    if (items) {
      this.menuitems = items;
      this.filter();
    }
  }

  private sortMenu() {
    this.menuitems.private.sort((menuItem, next) => menuItem.label.localeCompare(next.label));
    this.menuitems.public.sort((menuItem, next) => menuItem.label.localeCompare(next.label));
    this.menuitems.dev.sort((menuItem, next) => menuItem.label.localeCompare(next.label));
  }

}

