import { MaterialsModule } from 'src/app/base/materials/materials.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookiesComponent } from './cookies.component';
import { DialogPolicyModule } from './dialog-policy/dialog-policy.module';
import { DialogPolicyComponent } from './dialog-policy/dialog-policy.component';
import { LayoutModule } from 'src/app/base/layout/layout.module';

const COOKIES_COMPONENTS = [
  CookiesComponent
];

@NgModule({
  imports: [
    CommonModule,
    MaterialsModule,
    LayoutModule,
    DialogPolicyModule
  ],
  entryComponents: [
    DialogPolicyComponent
  ],
  declarations: [
    ...COOKIES_COMPONENTS
  ],
  exports: [
    ...COOKIES_COMPONENTS
  ]
})
export class CookiesModule { }
