import { Action } from '@ngrx/store';

export const SPANISH = '[Mensaje] Spanish';
export const ENGLISH = '[Mensaje] English';


export class SpanishMessage implements Action {
    readonly type = SPANISH;
    constructor(public payload: string) { }
}

export class EnglishMessage implements Action {
    readonly type = ENGLISH;
    constructor(public payload: string) { }
}
export type MessageActions = SpanishMessage | EnglishMessage;