import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Page } from './Page';
import { SeoService } from './seo.service';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit, OnChanges, OnDestroy {


  @Input() title?: string;
  @Input() description?: string;
  @Input() keywords?: string;

  @Output() changed: EventEmitter<Page> = new EventEmitter();

  routerSubscription: Subscription;
  page: Page;

  constructor(
    private seoService: SeoService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.loadServices();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const page = {
      title: this.title,
      description: this.description,
      keywords: this.keywords
    };
    this.title = changes.title ? changes.title.currentValue : this.title;
    this.description = changes.description ? changes.description.currentValue : this.description;
    this.keywords = changes.keywords ? changes.keywords.currentValue : this.keywords;
    this.update(page);
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  loadServices() {
    this.routerSubscription = this.activatedRoute.data.subscribe(page => {
      if (page.title || page.description || page.keywords) {
        this.page = new Page(page.tilte, page.description, page.keywords);
        this.update(page);
      }
    });
  }

  update(page: any) {
    this.seoService.updateTitle(this.setTitle(page.title));
    this.seoService.updateDescription(page.description);
    this.seoService.updateKeywords(page.keywords);
    this.title = page.title;
    this.description = page.description;
    this.keywords = page.keywords;
    this.changed.emit(page);
  }

  setTitle(title: string) {
    const defaultTitle = [];
    if (title.trim().length > 0) {
      defaultTitle.push(title.toString());
    }
    return defaultTitle.join(' - ');
  }
}
