<div *ngIf="!isConsented" class="outside" (click)="consent($event)"></div>
<div *ngIf="!isConsented" class="cookies-content" fxLayout="row" [ngClass]="{'show':!isConsented, 'hide':isConsented}">
    <div fxFlex></div>
    <mat-card>
        <mat-card-content>
            <div>
                <div fxLayout="row" padding-y-10 fxLayoutGap="4px">
                    <span fxFlex fxLayoutAlign="start center">
                        <div fxLayout="column" fxLayoutAlign="start start">
                            <div fxFlex>
                                <a mat-button color="accent" (click)="navigateTo('cookies-policy')" class="link"
                                    i18n>Uso de Cookies</a>
                                <span i18n>Usamos cookies propias y de terceros para mejorar su experiencia.</span>
                            </div>
                            <div fxFlex>
                                <span i18n>Consideramos que si continúa navegando acepta el uso de cookies y las
                                    <a (click)="navitateTo('conditions')" class="link">Condiciones del Servicio</a>
                                </span>
                            </div>
                        </div>
                    </span>
                </div>
                <div fxLayoutAlign="start end" padding-y-10>
                    <button fxFlex color="primary" mat-raised-button (click)="consent($event)" i18n>Entendido</button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <div fxFlex></div>
</div>