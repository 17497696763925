<div padding-20>
    <div cdkDropListGroup>
        <div class="container" fxFlex>

            <h2 i18n>Pendiente</h2>
            <div class="list">
                <form fxLayout="column" fxFlex [formGroup]="frm" padding-20>
                    <mat-form-field fxFlex>
                        <mat-label i18n>Historia de Usuario</mat-label>
                        <input #inputText formControlName="name" matInput>
                        <mat-error *ngIf="hasError('name', 'required')" i18n>El campo es obligatorio</mat-error>

                    </mat-form-field>

                    <mat-form-field fxFlex>
                        <mat-label i18n>Descripción</mat-label>
                        <textarea formControlName="description" matInput></textarea>

                    </mat-form-field>

                    <button (click)="sendFrm()" mat-raised-button>Nueva</button>
                </form>
            </div>
            <div cdkDropList [cdkDropListData]="todo" class="list" (cdkDropListDropped)="drop($event)">
                <div class="box" *ngFor="let item of todo" cdkDrag>{{item.id}} {{item.name}}</div>
            </div>
        </div>

        <div class="container" fxFlex>
            <h2 i18n>En progreso</h2>

            <div cdkDropList [cdkDropListData]="progress" class="list" (cdkDropListDropped)="drop($event)">
                <div class="box" *ngFor="let item of progress" cdkDrag>{{item.id}} {{item.name}}</div>
            </div>
        </div>

        <div class="container" fxFlex>
            <h2 i18n>Hecho</h2>

            <div cdkDropList [cdkDropListData]="done" class="list" (cdkDropListDropped)="drop($event)">
                <div class="box" *ngFor="let item of done" cdkDrag>{{item.id}} {{item.name}}</div>
            </div>
        </div>

        <div class="container" fxFlex>
            <h2 i18n>Liberado</h2>

            <div cdkDropList [cdkDropListData]="released" class="list" (cdkDropListDropped)="drop($event)">
                <div class="box" *ngFor="let item of released" cdkDrag>{{item.id}} {{item.name}}</div>
            </div>
        </div>
    </div>
</div>