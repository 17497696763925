import { PrivacyModule } from './privacy/privacy.module';
import { CookiesPolicyModule } from './cookies-policy/cookies-policy.module';
import { ConditionsModule } from './conditions/conditions.module';
import { AuthModule } from './auth/auth.module';
import { HomeModule } from './home/home.module';
import { MeModule } from './me/me.module';
import { AgileModule } from './agile/agile.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RoutesRoutingModule } from './routes-routing.module';

const ROUTES_MODULES = [
  AgileModule,
  AuthModule,
  ConditionsModule,
  CookiesPolicyModule,
  HomeModule,
  MeModule,
  PrivacyModule,
];

@NgModule({
  declarations: [],
  imports: [
    ...ROUTES_MODULES,
    CommonModule,
    RoutesRoutingModule
  ]
})
export class RoutesModule { }
