import { OAuthService } from 'angular-oauth2-oidc';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class OauthInterceptor implements HttpInterceptor {

    constructor(
        protected oauthService: OAuthService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Handle request
        if (req.withCredentials) {
            req = this.cloneRequestAndSetAuthHeaders(req);
        }

        // Handle response
        return next.handle(req);
    }

    private cloneRequestAndSetAuthHeaders(request: HttpRequest<any>) {
        const token = this.oauthService.getAccessToken();
        return !token ? request : request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + token
            }
        });
    }

}


