import { Injectable } from '@angular/core';
import { AuthApiService } from 'src/app/base/api/auth-api.service';

@Injectable({
  providedIn: 'root'
})
export class ScrumBoardService extends AuthApiService {


}
