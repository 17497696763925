import { TechRadarModule } from './tech-radar/tech-radar.module';
import { ScrumBoardModule } from './scrum-board/scrum-board.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AgileRoutingModule } from './agile-routing.module';
import { AgileComponent } from './agile.component';

const AGILE_MODULES = [
  ScrumBoardModule,
  TechRadarModule,
];

@NgModule({
  declarations: [AgileComponent],
  imports: [
    ...AGILE_MODULES,
    CommonModule,
    AgileRoutingModule
  ]
})
export class AgileModule { }
