import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TechRadarRoutingModule } from './tech-radar-routing.module';
import { TechRadarComponent } from './tech-radar.component';
import { BaseModule } from 'src/app/base/base.module';


@NgModule({
  declarations: [
    TechRadarComponent
  ],
  imports: [
    BaseModule,
    CommonModule,
    TechRadarRoutingModule
  ]
})
export class TechRadarModule { }
