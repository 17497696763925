import { UserStory } from './UserStory';
import { ScrumBoardService } from './scrum-board.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-scrum-board',
  templateUrl: './scrum-board.component.html',
  styleUrls: ['./scrum-board.component.scss']
})
export class ScrumBoardComponent implements OnInit {
  progress: UserStory[] = [];
  released: UserStory[] = [];
  todo: UserStory[] = [];
  done: UserStory[] = [];
  frm: FormGroup;
  @ViewChild('inputText') inputTxt: ElementRef;

  constructor(private scrumBoardService: ScrumBoardService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.createForm();
    this.loadSubscriptions();
  }

  private createForm(): void {
    this.frm = this.fb.group({
      name: ['Historia de Usuario', [Validators.required]],
      description: ['Descripción de la historia de usuario']
    });
  }

  private loadSubscriptions(): void {
    // this.scrumBoardService.list().subscribe(todo => this.todo = todo);
    // this.scrumBoardService.list().subscribe(todo => this.todo = todo);
    // this.scrumBoardService.list().subscribe(todo => this.todo = todo);
  }

  private updateAllArrays(): void {
    this.todo.map(userStory => {
      userStory.status = 'TODO';
      return userStory;
    });
    this.progress.map(userStory => {
      userStory.status = 'IN_PROGRESS';
      return userStory;
    });
    this.done.map(userStory => {
      userStory.status = 'DONE';
      return userStory;
    });
    this.released.map(userStory => {
      userStory.status = 'RELEASED';
      return userStory;
    });
    console.log([...this.todo, ...this.progress, ...this.done, ...this.released]);
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.updateAllArrays();
    }
  }

  hasError(field: string, validation: string): boolean {
    return this.frm.controls[field].hasError(validation);
  }

  sendFrm(): void {
    if (this.frm.valid) {
      const id = [...this.todo, ...this.progress, ...this.done, ...this.released].length + 1;
      const userStory = { id, ...this.frm.value, status: 'TODO' };
      this.todo.unshift(userStory);
      this.frm.reset();
      this.inputTxt.nativeElement.focus();
    }
  }

}
