import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPolicyComponent } from './dialog-policy/dialog-policy.component';
import { Router } from '@angular/router';
const COOKIE_CONSENT = 'consent';
const COOKIE_CONSENT_EXPIRE_DAYS = 1 * 365;

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent {

  isConsented = false;

  constructor(
    public dialog: MatDialog,
    private router: Router
  ) {
    this.isConsented = this.getCookie(COOKIE_CONSENT) === '1';
  }

  private getCookie(name: string) {
    const ca = document.cookie.split(';');
    const caLen = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  private deleteCookie(name) {
    this.setCookie(name, '', -1);
  }

  private setCookie(name: string, value: string, expireDays: number, path: string = '') {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  consent(e: any) {
    this.isConsented = !this.isConsented;
    this.setCookie(COOKIE_CONSENT, this.isConsented ? '1' : '0', COOKIE_CONSENT_EXPIRE_DAYS);
    e.preventDefault();
  }


  openDialog() {
    const dialogRef = this.dialog.open(DialogPolicyComponent, {
      width: '640px',
      data: {
        accept: true
      }
    });
  }

  navigateTo(path: string) {
    this.router.navigate([path]);
  }

}
