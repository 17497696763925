import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Neo4jD3Component } from './neo4j-d3.component';
const COMPONENTS = [
  Neo4jD3Component
];
@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class Neo4jD3Module { }
