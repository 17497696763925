<app-page-title i18n-title title="Condiciones del servicio"></app-page-title>
<div fxLayoutAlign="center center" padding-y-20>
  <mat-card fxFlex.gt-xs="600px">
    <mat-card-header>
      <mat-card-title i18n>Condiciones del servicio</mat-card-title>
      <mat-card-subtitle i18n>Condiciones del servicio de Stop Propagation</mat-card-subtitle>
    </mat-card-header>
    <img matCardImage class="home-image" src="assets/terms.svg" i18n-alt alt="Imagen de condiciones del servicio">
    <mat-card-content>
      <h3 i18n>1. ¿Qué es MemorArt?</h3>
      <p i18n>MEMORART es una plataforma de contenidos multimedia para almacenar la información de cualquier tipo de
        entidad para la posteridad.</p>
      <p i18n>Desde qué se quiere que se recuerde de nosotros, qué queremos recordar de un familiar, imágenes, sonidos,
        eventos en directo o diferido... </p>
      <p i18n>El objetivo de MEMORART es almacenar lo que realmente es importante para nosotros o nuestros seres
        queridos. Una canción, un poema, una dedicatoria, unas palabras, una imagen,...</p>
      <p i18n>Una de las funciones de MEMORART es ofrecer una primera impresión de la persona.</p>
      <h3 i18n>2. Objetivo principal</h3>
      <p i18n>El objetivo de MEMORART es almacenar lo que realmente es importante para nosotros o nuestros seres
        queridos.</p>
      <mat-nav-list>
        <mat-list-item i18n>Una canción</mat-list-item>
        <mat-list-item i18n>Un poema</mat-list-item>
        <mat-list-item i18n>Una cita</mat-list-item>
        <mat-list-item i18n>Un libro</mat-list-item>
        <mat-list-item i18n>Unas palabras</mat-list-item>
        <mat-list-item i18n>Una idea</mat-list-item>
        <mat-list-item i18n>Una imagen</mat-list-item>
        <mat-list-item i18n>Una escena</mat-list-item>
        <mat-list-item i18n>Un vídeo</mat-list-item>
        <mat-list-item i18n>Un pensamiento</mat-list-item>
        <mat-list-item i18n>...</mat-list-item>
      </mat-nav-list>
      <h3 i18n>3. Responsable de tratamiento</h3>
      <p i18n>Memorart.com</p>
      <h3 i18n>4. Finalidad</h3>
      <p i18n>Identificarle como usuario de los diferentes entornos y ecosistemas de Memorart para darle acceso a sus
        correspondientes funcionalidades, que son, disfrutar recibir y participar de la informació, contenidos,
        productos, servicios, encuestas, eventos y talleres con la gestión y personalización correspondiente para todo
        ello. Incluyendo mantener relaciones comerciales transaccionales.</p>
      <h3 i18n>5. Legitimación</h3>
      <p i18n>Consentimiento del usuario interesado e interés legítimo</p>
      <h3 i18n>6. Destinatarios</h3>
      <p i18n>No se cederán datos a tercerso, salvo oblicación o permiso legal /contractual</p>
      <h3 i18n>7. Derechos</h3>
      <p i18n>Acceso, rectificación, supresión y oposición, entre otros. </p>


    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" fxFlex i18n routerLink="/">Entendido</button>
    </mat-card-actions>
  </mat-card>
</div>