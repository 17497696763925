import { MaterialsModule } from 'src/app/base/materials/materials.module';
import { LayoutModule } from 'src/app/base/layout/layout.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { MenuService } from './menu.service';

const MENU_COMPONENTS = [
  MenuComponent
];

@NgModule({
  declarations: [
    ...MENU_COMPONENTS
  ],
  imports: [
    LayoutModule,
    MaterialsModule,
    CommonModule
  ],
  providers: [
    // LoginService,
    MenuService
  ],
  exports: [
    ...MENU_COMPONENTS
  ]
})
export class MenuModule { }
