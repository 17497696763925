import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuService } from 'src/app/base/components/menu/menu.service';
import { PrivacyComponent } from './privacy.component';

const path = 'privacy';
const routes: Routes = [{
  path,
  component: PrivacyComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivacyRoutingModule {
  constructor(private menuService: MenuService) {
    this.menuService.addPublicItem('Aviso Legal y Responsabilidad', path);
  }
}
