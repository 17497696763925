<div fxFlex>
    <form padding-y-10>
        <mat-form-field fxFlex>
            <input type="search" autocomplete="off" (keyup)="filter($event)" fxFlex matInput i18n-placeholder
                placeholder="Buscar en el menú" value="">
        </mat-form-field>
    </form>

    <mat-nav-list role="navigation" class="menu">
        <section *ngIf="logged && privateItems.length>0">
            <mat-divider></mat-divider>
            <a mat-list-item focus="true" tabindex="0" *ngFor="let menuItem of privateItems"
                (keyup)="keyNavigateTo($event,menuItem.route)" (click)="navigateTo(menuItem.route)"
                role="button">{{menuItem.label}}</a>
        </section>
        <section *ngIf="publicItems.length>0">
            <mat-divider></mat-divider>
            <a mat-list-item focus="true" tabindex="0" *ngFor="let menuItem of publicItems"
                (keyup)="keyNavigateTo($event,menuItem.route)" (click)="navigateTo(menuItem.route)"
                role="button">{{menuItem.label}}</a>
        </section>

    </mat-nav-list>

</div>