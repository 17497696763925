import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './cookies-policy.component.html',
  styleUrls: ['./cookies-policy.component.scss']
})
export class CookiesPolicyComponent implements OnInit {

  isConsented = false;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  consent() {
    const COOKIE_CONSENT = 'consent';
    const COOKIE_CONSENT_EXPIRE_DAYS = 1 * 365;
    this.isConsented = !this.isConsented;
    this.setCookie(COOKIE_CONSENT, this.isConsented ? '1' : '0', COOKIE_CONSENT_EXPIRE_DAYS);
    this.router.navigate(['']);
  }

  private setCookie(name: string, value: string, expireDays: number, path: string = '') {
    const d: Date = new Date();
    const DAY_MILISECONDS = 24 * 60 * 60 * 1000;
    d.setTime(d.getTime() + expireDays * DAY_MILISECONDS);
    const expires = `expires=${d.toUTCString()}`;
    const cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

}
