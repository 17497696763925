import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

const LAYOUT_MODULES = [
  FlexLayoutModule
];

@NgModule({
  imports: [
    CommonModule,
    ...LAYOUT_MODULES
  ],
  exports: [
    ...LAYOUT_MODULES
  ],
  declarations: []
})
export class LayoutModule { }
