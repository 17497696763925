import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Technology } from './tech-radar';

@Injectable({
  providedIn: 'root'
})
export class TechRadarService {

  getTechniques(): Observable<Technology[]> {
    return of([
      new Technology('infrastructure as code', 'adopt', `Aunque la infraestructura como código es una técnica relativamente antigua (la presentamos en el Radar en 2011), se ha vuelto de vital importancia en la era de la nube moderna, donde el acto de configurar la infraestructura se ha convertido en el paso de instrucciones de configuración a una plataforma en la nube. . Cuando decimos "como código" queremos decir que todas las buenas prácticas que hemos aprendido en el mundo del software deben aplicarse a la infraestructura. Usando control de fuente, adhiriéndose al principio DRY, la modularización, la capacidad de mantenimiento y el uso de pruebas e implementación automatizadas son prácticas críticas. Aquellos de nosotros con una gran experiencia en software e infraestructura debemos sentir empatía y apoyar a los colegas que no lo tienen. Decir "tratar la infraestructura como si fuera un código" no es suficiente; Necesitamos asegurarnos de que los aprendizajes obtenidos con tanto esfuerzo del mundo del software también se apliquen de manera consistente en todo el ámbito de la infraestructura.`),
      new Technology('pipeline as code', 'adopt', `La técnica de las pipelines como código enfatiza que la configuración de las pipelines de entrega que construyen, prueban e implementan nuestras aplicaciones o infraestructura debe tratarse como código; deben colocarse bajo control de fuente y modularizarse en componentes reutilizables con pruebas e implementación automatizadas. A medida que las organizaciones se trasladan a equipos autónomos descentralizados que crean microservicios o micro frontends , la necesidad de prácticas de ingeniería en la gestión de pipelines a medida que aumenta el código para seguir construyendo e implementando software de manera coherente dentro de la organización. Esta necesidad ha dado lugar a plantillas y herramientas de canal de entrega que permiten una forma estandarizada de construir e implementar servicios y aplicaciones. Estas herramientas utilizan las pipelines de entrega declarativasde aplicaciones, adoptando un plan de canalización para ejecutar las tareas subyacentes para varias etapas del ciclo de vida de una entrega, como compilación, prueba e implementación; y abstraen los detalles de implementación. La capacidad de construir, probar e implementar pipelines como código debería ser uno de los criterios de evaluación para elegir una herramienta CI / CD.`),
      new Technology('microfrontends', 'trial', `Hemos visto importantes beneficios al introducir microservicios , que han permitido a los equipos escalar la entrega de servicios implementados y mantenidos de forma independiente. Desafortunadamente, también hemos visto a muchos equipos crear un monolito de front-end, una gran aplicación de navegador entrelazada que se encuentra en la parte superior de los servicios de back-end, neutralizando en gran medida los beneficios de los microservicios. Las micro interfaces han seguido ganando popularidad desde que se introdujeron por primera vez. Hemos visto a muchos equipos adoptar alguna forma de esta arquitectura como una forma de gestionar la complejidad de varios desarrolladores y equipos que contribuyen a la misma experiencia de usuario. En junio del año pasado, uno de los creadores de esta técnica publicó un artículo introductorioque sirve como referencia para micro frontends. Muestra cómo se puede implementar este estilo utilizando varios mecanismos de programación web y crea una aplicación de ejemplo utilizando React.js . Estamos seguros de que este estilo crecerá en popularidad a medida que las organizaciones más grandes intenten descomponer el desarrollo de la interfaz de usuario en varios equipos.`),
      new Technology('pairing remote', 'trial', `Creemos firmemente que la programación por pares mejora la calidad del código, difunde el conocimiento en todo el equipo y permite una entrega de software más rápida en general. Sin embargo, en un mundo posterior a COVID-19, muchos equipos de software estarán distribuidos o serán completamente remotos, y en esta situación recomendamos el emparejamiento remoto pragmático : ajustar las prácticas de emparejamiento a lo que sea posible con las herramientas disponibles. Considere herramientas como Visual Studio Live Sharepara una colaboración eficiente y de baja latencia. Solo recurra al uso compartido de píxeles si ambos participantes residen en una proximidad geográfica relativa y tienen conexiones a Internet de gran ancho de banda. Empareje a los desarrolladores que se encuentran en zonas horarias similares en lugar de esperar que el emparejamiento funcione entre los participantes independientemente de su ubicación. Si el emparejamiento no funciona por razones logísticas, recurra a prácticas como la programación individual aumentada a través de revisiones de código, colaboración de solicitud de extracción (pero tenga cuidado con las ramas de larga duración con Gitflow ) o sesiones de emparejamiento más cortas para partes críticas del código. Nos hemos involucrado en el emparejamiento remoto durante años y hemos descubierto que es efectivo si se hace con una dosis de pragmatismo.`),
      new Technology('angular.io', 'adopt', ``),
      new Technology('react', 'assess', ``),
      new Technology('react1', 'assess', ``),
      new Technology('react2', 'assess', ``),
      new Technology('react9', 'assess', ``),
      new Technology('react8', 'assess', ``),
      new Technology('react3', 'assess', ``),
      new Technology('react4', 'assess', ``),
      new Technology('react5', 'assess', ``),
      new Technology('jquery', 'hold', ``),
      new Technology('jquery1', 'hold', ``),
      new Technology('jquery2', 'hold', ``),
      new Technology('jquery3', 'hold', ``),
      new Technology('jquery4', 'hold', ``),
    ]);
  }

  getLanguages(): Observable<Technology[]> {
    return of([
      new Technology('angular', 'adopt', ``),
    ]);
  }

  getTools(): Observable<Technology[]> {
    return of([
      new Technology('angular cli', 'adopt', ``),
      new Technology('angular material', 'adopt', ``),
      new Technology('flex layout', 'trial', ``),
      new Technology('electron', 'adopt', ``),
    ]);
  }

  getPlatforms(): Observable<Technology[]> {
    return of([
      new Technology('angular.io', 'adopt', ``),
    ]);
  }

  constructor() { }
}
