import { Component, Output } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.scss']
})
export class OauthComponent {

  @Output() updated = new BehaviorSubject(null);

  constructor(private oauthService: OAuthService) {
    this.oauthService.events.subscribe(events => {
      return this.updated.next(events);
    });
  }

  public login() {
    this.oauthService.initLoginFlow();
  }

  public logout() {
    this.oauthService.logOut();
  }

  public get name() {
    const claims: any = this.oauthService.getIdentityClaims();
    if (!claims) { return null; }
    // tslint:disable-next-line: no-string-literal
    return claims['given_name'];
  }

  public get email() {
    const claims: any = this.oauthService.getIdentityClaims();
    if (!claims) { return null; }
    return claims.email;
  }

  public get fullname() {
    const claims: any = this.oauthService.getIdentityClaims();
    if (!claims) { return null; }
    return claims.name;
  }

}
