<app-page-title i18n-title title="Política de uso de cookies"></app-page-title>
<div fxLayoutAlign="center center" padding-y-20>
    <mat-card fxFlex.gt-xs="600px">
        <mat-card-header>
            <mat-card-title i18n>Política de uso de cookies</mat-card-title>
            <mat-card-subtitle i18n>Infomración de política de uso de cookies</mat-card-subtitle>
        </mat-card-header>
        <img matCardImage class="home-image" src="assets/cookies.png" i18n-alt alt="Imagen de cookies">
        <mat-card-content>
            <h3 i18n>¿Qué es una cookie?</h3>
            <p i18n>Es un fichero que se descarga en su ordenador u otro dispositivo al acceder a determinadas páginas
                web que recogen información sobre su navegación en dicho sitio web. En algunos casos las cookies
                resultan necesarias para facilitar la navegación
                y permiten almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su
                equipo, entre otras cosas y, según la información que contengan y de la forma en que utilice su equipo,
                pueden utilizarse para reconocer
                al usuario.
            </p>
            <h3 i18n>Identificación de cookies utilizadas</h3>
            <p i18n>Siguiendo las directrices establecidas por la Agencia Española de Protección de Datos, las cookies
                empleadas en esta web se clasifican de la siguiente forma</p>
            <ul>
                <li>
                    <strong matLine i18n>Cookies propias</strong>
                    <div i18n>Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio
                        gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.
                    </div>
                </li>
                <li>
                    <strong matLine i18n>Cookies de tercero</strong>
                    <div i18n>Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio que no
                        es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las
                        cookies.</div>
                </li>
                <li>
                    <strong matLine i18n>Cookies de sesión</strong>
                    <div i18n>Recaban y almacenan datos mientras el usuario accede a una página web.</div>
                </li>
                <li>
                    <strong matLine i18n>Cookies técnicas</strong>
                    <div i18n>Permiten al usuario la navegación a través de una página web, plataforma o aplicación y la
                        utilización de las diferentes opciones o servicios que en ella existan.</div>
                </li>
                <li>
                    <strong matLine i18n>Cookies de personalización</strong>
                    <div i18n>Permiten al usuario acceder al servicio con algunas características de carácter general
                        predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo
                        serian el idioma, el tipo de navegador a través del
                        cual accede al servicio, la configuración regional desde donde accede al servicio, etc.</div>
                </li>
                <li>
                    <strong matLine i18n>Cookies de análisis</strong>
                    <div i18n> Permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de
                        los usuarios de los sitios web a los que están vinculadas. La información recogida mediante este
                        tipo de cookies se utiliza en la medición de la
                        actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de
                        navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de
                        introducir mejoras en función del análisis de los
                        datos de uso que hacen los usuarios del servicio.</div>
                </li>
            </ul>

            <h3 i18n>Este sitio utiliza</h3>
            <h4 i18n>Cookies de análisis</h4>
            <p i18n>Este sitio utiliza cookies de Google Analytics para cuantificar el número de usuarios que visitan la
                Web. Estas cookies permiten medir y analizar la forma en que los usuarios navegan por la Web. Esta
                información nos permite mejorar continuamente
                nuestro servicio y la experiencia del Usuario en la Web. Para obtener más información, puedes consultar
                la página de privacidad de Google Analytics
            </p>

            <h3 i18n>Aceptación de la política de cookies</h3>
            <p i18n> Pulsando el botón Entendido se asume que usted acepta el uso de cookies.</p>

            <h3 i18n>Puede permitir, bloquear o eliminar las cookies</h3>
            <p i18n>Las cookies instaladas en su equipo se pueden bloquear o eliminar mediante la configuración de las
                opciones del navegador instalado en su ordenador.</p>
            <ul>
                <li>
                    <a mat-button href="http://support.mozilla.org/es/products/firefox/cookies"
                        target="_blank">Firefox</a>
                </li>
                <li>
                    <a mat-button href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647"
                        target="_blank">Chrome</a>
                </li>
                <li>
                    <a mat-button
                        href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9"
                        target="_blank">Explorer</a>
                </li>
                <li>
                    <a mat-button href="http://support.apple.com/kb/ph5042" target="_blank">Safari</a>
                </li>
                <li>
                    <a mat-button href="http://help.opera.com/Windows/11.50/es-ES/cookies.html"
                        target="_blank">Opera</a>
                </li>
                <li>
                    <a mat-button href="https://es-es.facebook.com/about/privacy/ " target="_blank">Facebook</a>
                </li>
                <li>
                    <a mat-button href="https://twitter.com/privacy " target="_blank">Twitter</a>
                </li>
                <li>
                    <a mat-button href="http://es.about.pinterest.com/privacy/ " target="_blank">Pinterest</a>
                </li>
                <li>
                    <a mat-button href="http://es.linkedin.com/legal/cookie-policy " target="_blank">Linkedin</a>
                </li>
                <li>
                    <a mat-button href="https://www.google.es/intl/es/policies/technologies/types/"
                        target="_blank">Google
                        Plus</a>
                </li>
            </ul>

        </mat-card-content>
        <mat-card-actions>
            <button fxFlex mat-raised-button (click)="consent()" color="primary">Entendido</button>
        </mat-card-actions>
    </mat-card>
</div>