import { ScrumBoardService } from './scrum-board.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrumBoardRoutingModule } from './scrum-board-routing.module';
import { ScrumBoardComponent } from './scrum-board.component';
import { BaseModule } from 'src/app/base/base.module';


@NgModule({
  declarations: [ScrumBoardComponent],
  imports: [
    BaseModule,
    CommonModule,
    ScrumBoardRoutingModule
  ],
  providers: [
    ScrumBoardService
  ]
})
export class ScrumBoardModule { }
