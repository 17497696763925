import { Account } from './Account';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'me'
})
export class MePipe implements PipeTransform {

  transform(value: Account, ...args: unknown[]): unknown {
    return `${value.name} ${value.lastName} (${value.age})`;
    // return null;
  }

}
