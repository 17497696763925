import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { LayoutModule } from '../../layout/layout.module';
import { MaterialsModule } from '../../materials/materials.module';

const FOOTER_COMPONENTS = [
  FooterComponent
];

@NgModule({
  declarations: [
    ...FOOTER_COMPONENTS
  ],
  imports: [
    MaterialsModule,
    LayoutModule,
    CommonModule
  ],
  exports: [
    ...FOOTER_COMPONENTS
  ]
})
export class FooterModule { }
