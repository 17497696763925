<mat-toolbar color="primary" fxLayoutAlign="center center" fxLayoutGap.gt-xs="20px">
    <button mat-icon-button (click)="drawerL.toggle()">
        <mat-icon>museum</mat-icon>
    </button>
    <span fxHide.xs>
        <div i18n>MEMORART</div>
    </span>
    <span fxHide.xs fxFlex></span>
    <app-oauth></app-oauth>

    <!-- <button fxHide.xs mat-button color="primary" i18n>¿Qué es Cl@ve?</button> -->
</mat-toolbar>