import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTitleComponent } from './page-title.component';

const PAGE_TITLE_COMPONENTS = [
  PageTitleComponent
];

@NgModule({
  declarations: [
    ...PAGE_TITLE_COMPONENTS
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...PAGE_TITLE_COMPONENTS
  ]
})
export class PageTitleModule { }
