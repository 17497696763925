import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { TechRadarService } from './tech-radar.service';
import { TechRadar, Technology, Sector, Tech } from './tech-radar';

export class Point {
  x: number;
  y: number;
}

export class Polar {
  distance: number;
  radians: number;
}

@Component({
  selector: 'app-tech-radar',
  templateUrl: './tech-radar.component.html',
  styleUrls: ['./tech-radar.component.scss']
})
export class TechRadarComponent extends TechRadar implements OnInit {

  private canvas: any;

  readonly size = 800;
  readonly lightgrey = 'rgba(0,0,0,.3)';

  // colors
  readonly adoptColor = 'rgba(220,220,255,.8)';
  readonly trialColor = 'rgba(220,255,220,.8)';
  readonly assessColor = 'rgba(255,255,220,.8)';
  readonly holdColor = 'rgba(255,220,220,.8)';
  // green
  // readonly adoptColor = 'rgba(0,100,0,.8)';
  // readonly trialColor = 'rgba(0,150,0,.8)';
  // readonly assessColor = 'rgba(0,200,0,.8)';
  // readonly holdColor = 'rgba(0,220,0,.8)';
  // red
  // readonly adoptColor = 'rgba(100,0,0,.8)';
  // readonly trialColor = 'rgba(150,0,0,.8)';
  // readonly assessColor = 'rgba(200,0,0,.8)';
  // readonly holdColor = 'rgba(220,0,0,.8)';

  visible: boolean;
  technologies: Tech[] = [];
  constructor(private techRadarService: TechRadarService) {
    super();
  }

  ngOnInit(): void {
    this.render();
  }

  display(tech: Tech): void {
    console.log(tech);
  }

  private displayTechniquesSVG(): void {
    const radius = this.size / 4;
    const cartesian = this.polarToCartesian(radius, Math.PI / 4);
    this.techRadarService.getTechniques().subscribe((technologies: Technology[]) => {
      this.displayTechnologiesSVG(technologies
        .filter(t => t.state === this.states[0]), 0, this.adoptColor);
      this.displayTechnologiesSVG(technologies
        .filter(t => t.state === this.states[1]), radius, this.trialColor);
      this.displayTechnologiesSVG(technologies
        .filter(t => t.state === this.states[2]), 2 * radius, this.assessColor);
      this.displayTechnologiesSVG(technologies
        .filter(t => t.state === this.states[3]), 3 * radius, this.holdColor);
    });
  }

  private cartesianToPolar(x: number, y: number): Polar {
    const polar = new Polar();
    polar.distance = Math.sqrt(x * x + y * y);
    polar.radians = Math.atan2(y, x);
    return polar;
  }

  private polarToCartesian(r: number, theta: number): Point {
    const point = new Point();
    point.x = r * Math.cos(theta);
    point.y = r * Math.sin(theta);
    return point;
  }

  private displayTechnologiesSVG(list: Technology[], radius, color): void {
    const length = list.length;
    const distance = this.size / 4;
    const radians = (Math.PI / 4);
    const polar = this.polarToCartesian(radius, radians);
    console.log(radius);
    list.forEach((t, index) => {
      const tech = new Tech(t.name, t.state, t.description);
      tech.x = this.size - polar.x + this.polarToCartesian(distance / length, radians * index).x;
      tech.y = this.size - polar.y - this.polarToCartesian(distance / length, radians * index).y;
      // tech.x = minX + this.polarToCartesian(distance / 2, radians * (1 + index)).x;
      // tech.y = minY + this.polarToCartesian(distance / 2, radians * (1 + index)).y;
      tech.index = index;
      tech.color = color;
      this.technologies.push(tech);
    });
  }

  private render(): void {
    const cache = this.loadFromSession();
    this.displayLanguages();
    this.displayTechniquesSVG();

    if (cache === this.languages.name) {
      this.displayLanguages();
    }
    if (cache === this.tools.name) {
      this.displayTools();
    }
    if (cache === this.platforms.name) {
      this.displayPlatforms();
    }
    if (cache === this.techniques.name) {
      this.displayTechniques();
    }
  }

  displayLanguages(): void {
    this.techRadarService.getLanguages().subscribe(technology => technology.forEach(tech => this.languages.add(tech)));
    this.createCanvas(this.languages);
  }

  displayTools(): void {
    this.techRadarService.getTools().subscribe(technology => technology.forEach(tech => this.tools.add(tech)));
    this.createCanvas(this.tools);
  }

  displayPlatforms(): void {
    this.techRadarService.getPlatforms().subscribe(technology => technology.forEach(tech => this.platforms.add(tech)));
    this.createCanvas(this.platforms);
  }

  displayTechniques(): void {
    this.techRadarService.getTechniques().subscribe(technology => technology.forEach(tech => this.techniques.add(tech)));
    this.createCanvas(this.techniques);
  }

  private loadFromSession(): string {
    const cache = sessionStorage.getItem('tech-radar');
    return cache ? cache : 'tools';
  }

  private saveToSession(value: string): void {
    sessionStorage.setItem('tech-radar', value);
  }

  private createCanvas(sector: Sector): void {
    this.displaySector(sector);
    this.createSVG(sector);
    this.saveToSession(sector.name);
  }

  private createSVG(sector: Sector): void {

  }

  private displaySector(sector: Sector): void {
    this.canvas = document.getElementById('canvas');
    const ctx = this.canvas.getContext('2d');
    this.createSectors(ctx);
    this.createStates(ctx);
    this.displayTechnologies(ctx, sector.technologies.filter(tech => tech.state === this.states[0]), 20);
    this.displayTechnologies(ctx, sector.technologies.filter(tech => tech.state === this.states[1]), 118);
    this.displayTechnologies(ctx, sector.technologies.filter(tech => tech.state === this.states[2]), 260);
    this.displayTechnologies(ctx, sector.technologies.filter(tech => tech.state === this.states[3]), 402);
    ctx.font = '28px Arial';
    ctx.fillText(sector.name.toUpperCase(), 50, this.size - 350);
  }

  private createStates(ctx: any): void {
    ctx.fillStyle = 'rgba(0,0,0,.8)';
    this.states.forEach((state: string, index: number) => {
      const padding = (this.size / 5 * index) + 50;
      ctx.font = '12px Arial';
      ctx.fillText(state.toUpperCase(), padding, 20);
    });
  }

  private createSectors(ctx: any): void {
    const fillStyles = [
      'rgba(255,220,220,1)', // red
      'rgba(255,255,220,1)', // yellow
      'rgba(220,255,220,1)', // green
      'rgba(220,220,255,1)', // blue
    ];
    fillStyles.forEach((style: string, index: number) => {
      ctx.beginPath();
      const radius = this.size - (index + 1) * this.size / 5;
      ctx.setLineDash([10, 5]);
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#CCC';
      ctx.arc(0, 0, radius, - radius, .5 * Math.PI);
      ctx.fillStyle = style;
      ctx.stroke();
      ctx.fill();
      ctx.closePath();
    });
  }

  private displayTechnologies(ctx, list: Technology[], offset: number): void {
    let startCenter = 0;
    const radius = 8;
    list.forEach((technology: Technology) => {
      const padding = radius * 2;
      startCenter += padding;
      const center = { x: startCenter + offset + padding, y: startCenter + offset + padding };
      ctx.setLineDash([0]);
      ctx.beginPath();
      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgba(0,0,0,.8)';
      ctx.fillStyle = 'rgba(0,0,0,.3)';
      ctx.arc(center.x, center.y, radius, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.fill();
      ctx.fillStyle = 'rgba(0,0,0,.6)';
      ctx.font = '12px Arial';
      ctx.fillText(technology.name.toUpperCase(), center.x + padding, center.y + 3);
      ctx.closePath();
    });
  }


}

