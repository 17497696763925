<app-header [drawerL]="drawerL" [drawerR]="drawerR"></app-header>
<app-cookies></app-cookies>
<mat-drawer-container class="container bg" autosize>
    <mat-drawer position="start" #drawerL class="drawer" mode="side">
        <app-menu [drawerL]="drawerL"></app-menu>
    </mat-drawer>
    <div class="sidenav-content" padding-y-20>
        <router-outlet></router-outlet>
    </div>
    <mat-drawer position="end" #drawerR class="drawer" mode="side">

    </mat-drawer>
</mat-drawer-container>