export class Cache {

  removeFromLocal(key: string): void {
    const value = this.loadFromLocal(key);
    if (value) {
      localStorage.removeItem(key);
    }
  }

  removeFromSession(key: string): void {
    const value = this.loadFromSession(key);
    if (value) {
      sessionStorage.removeItem(key);
    }
  }

  loadFromLocal(key: string): any {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : undefined;
  }

  loadFromSession(key: string): any {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : undefined;
  }

  saveToLocal(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  saveToSession(key: string, value: any): void {
    const isString = typeof value === 'string';
    const valueStringified = isString ? value : JSON.stringify(value);
    sessionStorage.setItem(key, valueStringified);
  }

}
