import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogPolicyData {
  accept: boolean;
}

@Component({
  selector: 'app-dialog-policy',
  templateUrl: './dialog-policy.component.html',
  styleUrls: ['./dialog-policy.component.scss']
})
export class DialogPolicyComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogPolicyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogPolicyData
  ) { }


  onNoClick(): void {
    this.dialogRef.close();
  }

}
