import { Action } from '@ngrx/store';

import * as fromMessage from './message.action';

export interface HomeState {
    text: string;
}

export const initialState = {
    text: 'hola mundo'
};

export const homeReducer = (state: HomeState = initialState, action: fromMessage.MessageActions) => {
    console.log(action);

    switch (action.type) {
        case fromMessage.SPANISH:
            return {
                ...state,
                text: action.payload
            }
        case fromMessage.ENGLISH:
            return {
                ...state,
                text: action.payload
            }
        default:
            return state;
    }


    // return state;
};
