export const colors = [
    '#68bdf6',
    '#6dce9e',
    '#faafc2',
    '#f2baf6',
    '#ff928c',
    '#fcea7e',
    '#ffc766',
    '#405f9e',
    '#a5abb6',
    '#78cecb',
    '#b88cbb',
    '#ced2d9',
    '#e84646',
    '#fa5f86',
    '#ffab1a',
    '#fcda19',
    '#797b80',
    '#c9d96f',
    '#47991f',
    '#70edee',
    '#ff75ea' // pink
];
